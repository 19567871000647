import React from "react"
import companyLogo from "../assets/images/white-logo-full.png"
import { config } from "../config";

const CompanyLogo = ({size='large', rounded=false, ...rest}) => {
	return (
		<div {...rest} >
			<img style={{width:config.sizes[size], borderRadius:rounded ? '50%' : 0}} src={companyLogo} alt={"Company logo"}/>
		</div>
	)
};

export default CompanyLogo;
