import React, {useEffect, useState} from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import SectionHeader from "../components/sections/partials/SectionHeader";
import {useSelector} from "react-redux";
import Contact from "./Contact";
import Equipment from "./Equipment";
import {Table} from "react-bootstrap";
import {config} from "../config";
import axios from "axios";
import Button from "../components/elements/Button";

const EquipmentManager = () => {

	const selector = useSelector(state => state);
	const signedIn = selector.auth.authenticated;
	const [equipment, setEquipment] = useState([]);
	const [subEquipment, setSubEquipment] = useState([]);
	const [category, setCategory] = useState();

	useEffect(() => {
		(async() => {
			let tempData = await axios.get(config.baseUrl + 'categories');
			console.log(tempData.data);
			setEquipment(tempData.data);
		})();

	}, []);

	useEffect(() => {
		if(!category) return;
		(async() => {
			let tempData = await axios.post(config.baseUrl + 'inventory/category', {category: category});
			setSubEquipment(tempData.data);
		})();
	}, [category]);

	if(!signedIn){
		return <Equipment />
	}

	const getEquipment = () =>  equipment.map((e, i) => {
		return (
			<tr key={i}>
				<td>{e.category}</td>
				<td>{e.count}</td>
				<td><Button tag="a" color="primary" onClick={() => setCategory(e.category)}>
					View
				</Button></td>
			</tr>
		);
	})

	const getSubEquipment = () =>  subEquipment.map((e, i) => {

		return (
			<tr key={i}>
				{/*<td>{e.category}</td>*/}
				{/*<td>{e.inventory_id}</td>*/}
				<td>{e.part_number}</td>
				<td>{e.available ? 'Available' : e.client}</td>
				<td><Button tag="a" color="primary">
					Edit
				</Button></td>
			</tr>
		);
	})


	return (
		<>
			<div className={'container'} style={{paddingTop: 16}}>
				{category ? <div style={{display: 'flex', flexDirection: 'row'}}>
					<h1>{category}</h1>
					<Button style={{marginLeft: 24, marginTop: 48}} tag="a" color="dark" onClick={() => setCategory(null)}>
						Back
					</Button>
				</div> : <h1>Equipment Manager</h1>}

				{category ?

					<Table striped bordered hover>
						<thead>
						<tr>
							{/*<th>Category</th>*/}
							{/*<th>Database Id</th>*/}
							<th>Part Number</th>
							<th>Client</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>
						{getSubEquipment()}
						</tbody>
					</Table> :
					<Table striped bordered hover>
						<thead>
						<tr>
							<th>Category</th>
							<th>Count</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>
						{getEquipment()}
						</tbody>
					</Table>}
			</div>
		</>
	);
}

export default EquipmentManager;
