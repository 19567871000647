import React from "react"
import {Spinner} from "react-bootstrap";

const AppSpinner = () => {

	const style = {
		position: 'absolute',
		top: 0,
		bottom: 0,
		right: 0,
		left: 0,
		backgroundColor: '#00000040',
		display: 'grid',
		placeItems: 'center',
	}

	return (
		<div style={style}>
			<h6>Loading...</h6>
		</div>
	)
};

export default AppSpinner;
