import React, {useState} from 'react';
import contactPicture from '../assets/images/EC Photo 4.jpg';
import {Form} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import Button from "../components/elements/Button";
import ctaImage from "../assets/images/EC photo 1.jpg";
import ButtonGroup from "../components/elements/ButtonGroup";
import {config} from "../config";
import axios from "axios";
import AppSpinner from "../components/spinner";

const Contact = () => {

	const {state} = useLocation();
	console.log('state', state);
	let message = '';
	let loc = '';
	if(state){
		if(state.message){
			message = state.message;
		}
		if(state.loc){
			loc = state.loc;
		}
	}

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [company, setCompany] = useState('');
	const [interests, setInterests] = useState(message);

	const [loading, setLoading] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const submit = async() => {
		setLoading(true);
		console.log('submitted');
		let data = {
			firstName: firstName,
			lastName: lastName,
			email: email,
			phone: phone,
			company: company,
			message: interests
		};
		let result = await axios.post(config.baseUrl + 'contacts', data);
		console.log(result);
		setLoading(false);
		setSubmitted(true);
		let formData = new FormData();
		formData.append("First Name", firstName);
		formData.append("Last Name", lastName);
		formData.append("E-Mail", email);
		formData.append("Phone", phone);
		formData.append("Company", company);
		formData.append("Message", interests);
		console.log(formData);
		axios.post("https://script.google.com/macros/s/AKfycbz1oYSBU9FpGV7_FTvVxN5IlXb2mXnZBZLx80brhIrcOm1KYR2EARqSWBjJENZYpXka/exec", formData)
		.then(response => {
			console.log('response', response);
		})
		.catch(error => {
			console.error(error);
		})
	}

	return (
    <>
		{loading && <AppSpinner/>}
		<div className="container">
			<div className="cta-action reveal-from-bottom" data-reveal-delay="600" style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(256px, 1fr))', paddingTop: 128, paddingBottom: 32}}>

				<div style={{display: 'grid', placeItems: 'center'}}>
					<div style={{marginBottom: 16}}>
						<h3 className="m-0" style={{marginBottom: 24}}>
							{submitted ? 'Thank you! Someone will get back to you shortly.' : 'Ready to get started?'}
						</h3>
						{submitted ? <Button tag="a" color="primary" wideMobile onClick={() => setSubmitted(false)}>
							Submit Again
						</Button> :
						<Form>
							<Form.Group controlId="formGridFirstName">
								<Form.Label style={{color: 'white'}}>First Name</Form.Label><br/>
								<Form.Control style={{padding: 8}} placeholder="Enter First Name" value={firstName} onChange={(event) => setFirstName(event.target.value)}/>
							</Form.Group>

							<Form.Group controlId="formGridLastName">
								<Form.Label style={{color: 'white'}}>Last Name</Form.Label><br/>
								<Form.Control style={{padding: 8}} placeholder="Enter Last Name" value={lastName} onChange={(event) => setLastName(event.target.value)} />
							</Form.Group>

							<Form.Group controlId="formGridCompany">
								<Form.Label style={{color: 'white'}}>Company</Form.Label><br/>
								<Form.Control style={{padding: 8}} placeholder="Enter Company" value={company} onChange={(event) => setCompany(event.target.value)} />
							</Form.Group>

							<Form.Group controlId="formGridEmail">
								<Form.Label style={{color: 'white'}}>Email</Form.Label><br/>
								<Form.Control style={{padding: 8}} type="email" placeholder="Enter email" value={email} onChange={(event) => setEmail(event.target.value)} />
							</Form.Group>

							<Form.Group controlId="formGridPhone">
								<Form.Label style={{color: 'white'}}>Phone</Form.Label><br/>
								<Form.Control style={{padding: 8}} placeholder="Phone Number" value={phone} onChange={(event) => setPhone(event.target.value)} />
							</Form.Group>

							<Form.Group controlId="formGridInterests">
								<Form.Label style={{color: 'white'}}>Message</Form.Label><br/>
								<Form.Control style={{padding: 8, width: '90%'}} as="textarea" rows={3} value={interests} onChange={(event) => setInterests(event.target.value)} />
							</Form.Group>

							<Button tag="a" color="primary" wideMobile onClick={submit}>
								Submit
							</Button>
						</Form>}
					</div>
				</div>
				<img src={contactPicture} style={{width: '100%', height: '100%', maxHeight: '100%', objectFit: 'cover'}}/>
			</div>
			<div className="cta-action reveal-from-bottom" data-reveal-delay="600" style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(256px, 1fr))', paddingTop: 48, paddingBottom: 32}}>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.4415894222734!2d-74.12275848459453!3d40.730307779329955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25155d35ffbbb%3A0x219d6bd38e32fb79!2s86%20Doremus%20Ave%2C%20Newark%2C%20NJ%2007105!5e0!3m2!1sen!2sus!4v1645541646879!5m2!1sen!2sus"
					width={'100%'} height={450} allowFullScreen="" loading="lazy"></iframe>

				<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white'}}>
					<h6>Michael Darrar</h6>
					<h6>86 Doremus Avenue</h6>
					<h6>Newark, NJ 07105</h6>
					<a style={{textDecorationLine: 'underline'}} href="mailto:mdarrar@expresschassis.com?Subject=Inventory Inquiry"><h6>mdarrar@expresschassis.com</h6></a>
					<h6>(201) 852-6524</h6>
				</div>
			</div>

		</div>
    </>
  );
}

export default Contact;
