import React, {useEffect, useState} from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import SectionHeader from "../components/sections/partials/SectionHeader";
import axios from "axios";
import {config} from "../config";
import {Table} from "react-bootstrap";
import Button from "../components/elements/Button";
import {useHistory} from "react-router";

const Equipment = () => {

	const history = useHistory();

	const [equipment, setEquipment] = useState([]);

	useEffect(() => {
		(async() => {
			let tempData = await axios.get(config.baseUrl + 'categories/available');
			console.log(tempData.data);
			setEquipment(tempData.data);
		})();

	}, []);

	const navigate = (route, data) => {
		if(data) history.push({pathname: route, state: data});
		else history.push(route);
	}

	const getEquipment = () =>  equipment.map((e, i) => {
		return (
			<tr key={i}>
				<td>{e.category}</td>
				<td>{e.count}</td>
				<td><Button tag="a" color="primary" onClick={() => navigate('/contact', {message: "I am interested in chassis type: " + e.category})}>
					Reserve
				</Button></td>
			</tr>
		);
	})

  return (
    <>
		<div className={'container'} style={{paddingTop: 16}}>
		<h1>Available Equipment</h1>
		<Table striped bordered hover>
			<thead>
			<tr>
				<th>Category</th>
				<th>Count</th>
				<th>Action</th>
			</tr>
			</thead>
			<tbody>
			{getEquipment()}
			</tbody>
		</Table>
			<div style={{paddingBottom: 32}}>
				<h3>Equipment Features</h3>
				<cite>– HUB piloted disc wheels<br/>– Sky Bitz GPS Ping Tracking<br/>– PSI tire monitoring<br/>– FHWA inspection sticker<br/>– LED lighting<br/>– OEM radial tires</cite>

			</div>
			</div>
    </>
  );
}

export default Equipment;
