import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import ctaImage from '../../assets/images/EC photo 1.jpg'

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
		  <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(256px, 1fr))', paddingTop: 48, paddingBottom: 48}}>
			  <img src={ctaImage}/>
			  <div style={{display: 'grid', placeItems: 'center'}}>
				  <div className="cta-action reveal-from-bottom" data-reveal-delay="600">
					  <h3 className="m-0" style={{marginBottom: 24}}>
						  Ready to get started?
					  </h3>
					  <ButtonGroup>
						  <Button tag="a" color="dark" wideMobile href="/equipment">
							  View Equipment
						  </Button>
						  <Button tag="a" color="primary" wideMobile href="/contact">
							  Contact Us
						  </Button>
					  </ButtonGroup>
				  </div>
			  </div>
		  </div>
        {/*<div*/}
        {/*  className={innerClasses}*/}
        {/*>*/}

          {/*<div className="cta-slogan">*/}

          {/*</div>*/}


        {/*</div>*/}
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
