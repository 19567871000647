import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import bgImage from '../../assets/images/highway-blur-dark.jpg';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const bgStyle = {
  	  backgroundImage: "url(" + bgImage + ")",
	  backgroundSize: 'cover',
	  backgroundPosition: 'center',
	  backgroundRepeat: 'no-repeat',
	  minHeight: 792,
	  display: 'grid',
	  placeItems: 'center'
  }

  return (
    <section
      {...props}
      className={outerClasses}
	  style={bgStyle}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              <span style={{color: '#919baa'}}>Welcome to</span> Express Chassis, LLC
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" style={{color: 'white'}} data-reveal-delay="400">The #1 Chassis Rental Service<br/> in New Jersey</p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup>
                  <Button tag="a" color="dark" wideMobile href="/equipment">
                    View Equipment
                  </Button>
                  <Button tag="a" color="primary" wideMobile href="/contact">
                    Contact Us
                    </Button>
                </ButtonGroup>
              </div>
            </div>
          </div>
          {/*<div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">*/}
          {/*    <img*/}
          {/*      className="has-shadow"*/}
          {/*      src={require('./../../assets/images/highway-blur.jpg')}*/}
          {/*      style={{height: 396, width: '100%', objectFit: 'cover'}}*/}
			{/*  	/>*/}
          {/*</div>*/}
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
