import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import Contact from "./Contact";
import axios from "axios";
import {config} from "../config";
import {Modal, Table} from "react-bootstrap";
import Button from "../components/elements/Button";
import moment from "moment";

const ContactManager = () => {

	const selector = useSelector(state => state);
	const signedIn = selector.auth.authenticated;

	const [contacts, setContacts] = useState([]);
	const [selected, setSelected] = useState(null);

	const refresh = async() => {
		let tempData = await axios.get(config.baseUrl + 'contacts');
		console.log(tempData.data);
		setContacts(tempData.data);
	}

	useEffect(() => {
		refresh();
		if(selected){
			axios.put(config.baseUrl + 'contacts', {contact_id: selected.contact_id});
		}
	}, [selected]);

	if(!signedIn){
		return <Contact />
	}

	const unread = {
		fontWeight: 'bold',
		color: 'white'
	}

	const read = {
		// fontWeight: 200
	}

	const getContacts = () =>  contacts.map((c, i) => {
		return (
			<tr key={i} style={{cursor: 'pointer'}} onClick={() => setSelected(c)}>
				<td style={c.opened ? read : unread}>{c.lastname}</td>
				<td style={c.opened ?  read : unread}>{c.firstname}</td>
				<td style={c.opened ?  read : unread}>{moment(c.timestamp).calendar()}</td>
			</tr>
		);
	})

  return (
    <>
		<div className={'container'} style={{paddingTop: 16}}>
			{selected ? <div style={{display: 'flex', flexDirection: 'row'}}>
				<h1>{selected.lastname + ', ' + selected.firstname}</h1>
				<Button style={{marginLeft: 24, marginTop: 48}} tag="a" color="dark" onClick={() => setSelected(null)}>
					Back
				</Button>
			</div> : <h1>Contacts</h1>}
			{selected ?
				<div>
					<p>Email: {selected.email}</p>
					<p>Phone: {selected.phone}</p>
					<p>Company: {selected.company}</p>
					<p>Message: {selected.message}</p>
					<p>Created: {moment(selected.timestamp).calendar()}</p>
				</div> :
			<Table striped bordered hover>
				<thead>
				<tr>
					<th>Last</th>
					<th>First</th>
					<th>Created</th>
				</tr>
				</thead>
				<tbody>
				{getContacts()}
				</tbody>
			</Table>}
		</div>
    </>
  );
}

export default ContactManager;
