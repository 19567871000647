import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {authenticate} from "../../../redux/AuthActions";

const FooterNav = ({
  className,
  ...props
}) => {

	const selector = useSelector(state => state);
	const signedIn = selector.auth.authenticated;
	const dispatch = useDispatch();

  const classes = classNames(
    'footer-nav',
    className
  );

  const signOut = () => {
	if(signedIn){
		localStorage.setItem('authenticated', 'false');
		dispatch(authenticate(false));
	}
  }

  useEffect(() => {
  	console.log('signed in', signedIn);
  }, [signedIn]);

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to={signedIn ? '/' : '/signin'} onClick={signOut}>{signedIn ? 'Sign Out' : 'Sign In'}</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;
