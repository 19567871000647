import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import SectionHeader from "../components/sections/partials/SectionHeader";

const Home = () => {

    const mission1 = {
        title: 'Driven',
        paragraph: 'Express Chassis LLC’s mission is to provide a cost efficient and convenient way for pre-qualified trucking companies to rent chassis.'
    };

    const mission2 = {
        title: 'Best In Class',
        paragraph: 'Express Chassis LLC will utilize state of the art equipment and advanced technology to create a seamless client experience.'
    };

    const mission3 = {
        title: 'Reliable',
        paragraph: 'We will provide dependable and efficient service that is superior to market standards.'
    };

    const mission4 = {
        title: 'Convenient',
        paragraph: 'We are certain that the convenience and quality of our process will make us the industry’s go-to chassis provider.'
    };

  return (
    <>
      <Hero />
        <SectionHeader data={mission1} className="center-content" />
        <FeaturesSplit invertMobile imageFill />
        <SectionHeader data={mission2} className="center-content" />
        <FeaturesTiles />
        <SectionHeader data={mission3} className="center-content" />
      <Testimonial />
        <SectionHeader data={mission4} className="center-content" />
      <Cta split />
    </>
  );
}

export default Home;
