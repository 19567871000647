import React, {useState} from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import SectionHeader from "../components/sections/partials/SectionHeader";
import {Link, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {authenticate} from "../redux/AuthActions";
import {config} from "../config";
import CompanyLogo from "../components/company-logo";
import axios from "axios";
import Button from "../components/elements/Button";
import AppSpinner from "../components/spinner";

const SignIn = () => {

	const [user, setUser] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const navigate = (route, data) => {
		if(data) history.push({pathname: route, state: data});
		else history.push(route);
	}

	const styles = {
		container: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: '90vh',
			backgroundColor: config.colors.secondary,
			paddingTop: 32
		},
		innerContainer: {
			backgroundColor: '#333',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			padding: 36,
			borderRadius: 4
		},
		input: {
			border: "1px solid black",
			padding: 6,
			marginBottom: 16
		},
		logo: {
			marginBottom: 16,
			borderRadius: 4,
			overflow: 'hidden'
		},
		signInButton: {
			marginBottom: 16
		},
		text: {
			color: config.colors.light,
			width: '100%',
			textAlign: 'center'
		},
		link: {
			color: config.colors.secondary,
			fontWeight: 'bold',
			cursor: 'pointer'
		}
	}

	const dispatch = useDispatch();

    const signIn = async() => {
    	setLoading(true);
    	try {
			let response = await axios.post(config.baseUrl + 'users/login', {username: user, password: password});
			console.log(response);
			localStorage.setItem('authenticated', 'true');
			dispatch(authenticate(true));
			navigate('/');
		} catch (e) {
    		console.log(e);
		} finally {
    		setLoading(false);
		}

	}

	if(loading){
		return <AppSpinner />
	}


  return (
    <>
		<div style={styles.container}>
			<div style={styles.innerContainer}>
				<div style={{display: 'grid', placeItems: 'center'}}>
					<CompanyLogo size={'medium'} style={styles.logo}/>
				</div>

					<>
						<input value={user} placeholder={'Username'} style={styles.input} onChange={(event) => setUser(event.target.value)}/>
						<input type={'password'} value={password} placeholder={'Password'} style={styles.input} onChange={(event) => setPassword(event.target.value)}/>
						<Button tag="a" color="primary" wideMobile onClick={signIn}>Sign In</Button>
					</>

			</div>
		</div>
    </>
  );
}

export default SignIn;
