const INITIAL_STATE = {
	authenticated: localStorage.getItem('authenticated') === 'true',
};

const authReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'AUTHENTICATE':
			const authenticated = action.payload;

			return {...state, authenticated};
		default:
			return state;
	}
};

export default authReducer;
