export const config = {
	baseUrl: 'https://cors-everywhere.herokuapp.com/http://24.91.81.112:3001/',
	colors: {
		primary: '#dc2027',
		secondary: '#1A202F',
		light: '#f0f0f0',
	},
	sizes:{
		xxs:48,
		xs:64,
		small:128,
		medium:256,
		large:512
	},
}
